html,
body {
	background: transparent;
	margin: 0;
	padding: 0;
	height: 100%;
	overflow: hidden;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html,
body,
html *,
body * {
	-ms-user-select     : none;
	-moz-user-select    : none;
	-webkit-user-select : none;
	user-select         : none;
	cursor              : default;
	line-height         : normal;
}

#root,
#root-inner {
	width: 100%;
	height: 100%;
}