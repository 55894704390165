#settings-container {
	height: 100vh;
	margin: 0;

	.setThemeStyle(
		{
			background: @dark_placeholderInnerBackground;
		},
		{
			background: @light_placeholderInnerBackground;
		},
		@classicTheme: {
			background: @classic_settingTabBackground;
		},
		@clearBlueTheme: {
			background: @clearBlue_containerBackground;
		}
	);
}

body #settings-container,
body #settings-container p,
body #settings-container p * {
	user-select: text;
	cursor: auto;
}

#settings-container .ui.fluid.container {
	width: 100% !important;
	margin-left: 0 !important;
	margin-right: 0 !important;
}

#settings-container > .row {
	height: 100%;
}

#settings-container #settings-sidebar {
	height: 100%;
	padding: 0;
	margin: 0;
	position: fixed;
	overflow-y: scroll;
}

#settings-container #settings-sidebar #settings-menu {
	margin: 0;
	box-shadow: none;
	border: 0;
	width: 100%;

	.setThemeStyle(
		@clearBlueTheme: {
			background: @clearBlue_containerBackground;
		}
	);
}

#settings-container #settings-sidebar #settings-menu .item {
	border-radius: 0;

	.setThemeStyle(
		{
			background: @dark_placeholderInnerBackground;
			color: @dark_playerDetailSectionDataTitleText;
		},
		{
			background: @light_placeholderInnerBackground;
			color: @light_playerDetailSectionDataTitleText;
		},
		@classicTheme: {
			background: @classic_settingTabBackground;
			color: @classic_playerDetailSectionDataTitleText;
		},
		@clearBlueTheme: {
			background: @clearBlue_containerBackground;
			color: @clearBlue_playerDetailSectionDataTitleText;
		}
	);
}

#settings-container #settings-sidebar #settings-menu .item.active {
	.setThemeStyle(
		{
			background-color: @dark_toggleBackground;
		},
		{
			background-color: @light_toggleBackground;
		},
		@classicTheme: {
			background-color: @classic_toggleBackground;
			color: @classic_settingTabBackground;
		},
		@clearBlueTheme: {
			background-color: @clearBlue_toggleBackground;
		}
	);
}

#settings-container #settings-sidebar #settings-menu .item:before {
	.setThemeStyle(
		{
			background: @dark_playerTableRowBackground;
		},
		{
			background: @light_borderInnerGlow;
		},
		@classicTheme: {
			background: @classic_footerBorderInner;
		},
		@clearBlueTheme: {
			background: @clearBlue_playerTableRowBackground;
		}
	);
}

#settings-container #settings-sidebar #settings-menu .item:not(.active):hover {
	.setThemeStyle(
		{
			background-color: @dark_contextMenuShadow;
		},
		{
			background-color: @light_contextMenuShadow;
		},
		@classicTheme: {
			background-color: @classic_contextMenuShadow;
			color: @classic_settingTabBackground;
		},
		@clearBlueTheme: {
			background-color: @clearBlue_contextMenuShadow;
		}
	);	
}

#settings-container #settings-screen {
	position: absolute;
	right: 0;
	height: 100%;
	overflow-y: scroll;

	.setThemeStyle(
		{
			background: @dark_contextMenuBackground;
			box-shadow: -1px 0 0 0 @dark_borderInnerShadow;
		},
		{
			background: @light_contextMenuBackground;
			box-shadow: -1px 0 0 0 @light_borderInnerShadow;
		},
		@classicTheme: {
			background: @classic_contextMenuBackground;
			box-shadow: -1px 0 0 0 @classic_borderInnerShadow;
		},
		@clearBlueTheme: {
			background: @clearBlue_contextMenuBackground;
			box-shadow: -1px 0 0 0 @clearBlue_borderInnerShadow;
		}
	);
}

#settings-container #settings-screen .ui.form button.save,
#settings-container #settings-screen .ui.form button.export {
	.setThemeStyle(
		{
			background: @dark_borderOuter;
		},
		{
			background: @light_borderOuter;
		},
		@classicTheme: {
			background: @classic_borderOuter;
		},
		@clearBlueTheme: {
			background: @clearBlue_borderOuter;
		}
	);
}

#settings-container #settings-screen .ui.form button.export {
	margin-bottom: 4px;
}

#settings-container #settings-screen .ui.form button.save:active,
#settings-container #settings-screen .ui.form button.export:active {
	.setThemeStyle(
		{
			background: @dark_importantText;
		},
		{
			background: @light_importantText;
		},
		@classicTheme: {
			background: @classic_importantText;
		},
		@clearBlueTheme: {
			background: @clearBlue_importantText;
		}
	);
}

#settings-container #settings-screen .section-container {
	margin-bottom: 1.5rem;
}

#settings-container #settings-screen .section-container:nth-child(3) {
	margin-top: -30px;
}

#settings-container #settings-screen .section-container > h2 {
	margin-bottom: 0;

	.setThemeStyle(
		{
			color: @dark_importantText;
		},
		{
			color: @light_importantText;
		},
		@classicTheme: {
			color: @classic_importantText;
		},
		@clearBlueTheme: {
			color: @clearBlue_importantText;
		}
	);
}

#settings-container #settings-screen .section-container p,
#settings-container #settings-screen .section-container .field label,
#settings-container #settings-screen .section-container .field .ui.toggle.checkbox input label {
	font-style: normal;
	font-weight: normal;
	font-size: 1em;
}

#settings-container #settings-screen .section-container .field label,
#settings-container #settings-screen .section-container p,
#settings-container #settings-screen .section-container h3,
#settings-container #settings-screen .section-container .field .ui.toggle.checkbox input:checked ~ label {
	.setThemeStyle(
		{
			color: @dark_playerDetailSectionTitleText !important;
		},
		{
			color: @light_playerDetailSectionTitleText !important;
		},
		@classicTheme: {
			color: @classic_playerDetailSectionTitleText !important;
		},
		@clearBlueTheme: {
			color: @clearBlue_playerDetailSectionTitleText !important;
		}
	);
}

#settings-container #settings-screen .section-container .field .ui.toggle.checkbox input ~ label:before {
	.setThemeStyle(
		{
			background: @dark_playerTableRowBackground !important;
		},
		{
			background: @light_playerTableRowBackground !important;
		},
		@classicTheme: {
			background: @classic_playerTableRowBackground !important;
		},
		@clearBlueTheme: {
			background: @clearBlue_playerTableRowBackground !important;
		}
	);
}

#settings-container #settings-screen .section-container .field .ui.toggle.checkbox input ~ label:hover:before {
	.setThemeStyle(
		{
			background: @dark_rowAndLinkHoverBackground !important;
		},
		{
			background: @light_rowAndLinkHoverBackground !important;
		},
		@classicTheme: {
			background: @classic_rowAndLinkHoverBackground !important;
		},
		@clearBlueTheme: {
			background: @clearBlue_rowAndLinkHoverBackground !important;
		}
	);
}

#settings-container #settings-screen .section-container .field .ui.toggle.checkbox input:checked ~ label:before {
	.setThemeStyle(
		{
			background: @dark_importantTextRGBA65 !important;
		},
		{
			background: @light_importantTextRGBA65 !important;
		},
		@classicTheme: {
			background: @classic_importantTextRGBA65 !important;
		},
		@clearBlueTheme: {
			background: @clearBlue_importantTextRGBA65 !important;
		}
	);
}

#settings-container #settings-screen .section-container .field .ui.dropdown,
#settings-container #settings-screen .section-container .field textarea,
#settings-container #settings-screen .section-container .field > div.ui.input > input[type=text] {
	.setThemeStyle(
		{
			background: @dark_footerActiveLinkBackground !important;
		},
		{
			background: @light_footerActiveLinkBackground !important;
		},
		@classicTheme: {
			background: @classic_footerActiveLinkBackground !important;
		},
		@clearBlueTheme: {
			background: @clearBlue_footerActiveLinkBackground !important;
		}
	);
}

#settings-container #settings-screen .section-container .field textarea,
#settings-container #settings-screen .section-container .field .code pre,
#settings-container #settings-screen .section-container .field > div.ui.input > input[type=text] {
	cursor: text;

	.setThemeStyle(
		{
			color: @dark_playerDetailSectionTitleText !important;
		},
		{
			color: @light_playerDetailSectionTitleText !important;
		},
		@classicTheme: {
			color: @classic_playerDetailSectionTitleText !important;
		},
		@clearBlueTheme: {
			color: @clearBlue_playerDetailSectionTitleText !important;
		}
	);
}

#settings-container #settings-screen .section-container .field .code {
	min-height: 300px;
}

#settings-container #settings-screen .section-container .field textarea,
#settings-container #settings-screen .section-container .field .code textarea,
#settings-container #settings-screen .section-container .field .code pre {
	padding: 4px !important;
}

#settings-container #settings-screen .section-container .field .code textarea {
	height: auto !important;
	min-height: 100% !important;
}

#settings-container #settings-screen .section-container .field .ui.dropdown a.ui.label {
	.setThemeStyle(
		{
			background: @dark_borderOuterShadow;
		},
		{
			background: @light_borderOuterShadow;
		},
		@classicTheme: {
			background: @classic_borderOuterShadow;
		},
		@clearBlueTheme: {
			background: @clearBlue_borderOuterShadow;
		}
	);
}

#settings-container #settings-screen .section-container .field .ui.dropdown input.search,
#settings-container #settings-screen .section-container .field .ui.dropdown div.text {
	.setThemeStyle(
		{
			color: @dark_playerDetailSectionTitleText;
		},
		{
			color: @light_playerDetailSectionTitleText;
		},
		@classicTheme: {
			color: @classic_playerDetailSectionTitleText;
		},
		@clearBlueTheme: {
			color: @clearBlue_playerDetailSectionTitleText;
		}
	);
}

#settings-container #settings-screen .section-container .field > .ui-slider {
	border: 0;

	.setThemeStyle(
		{
			background: @dark_footerActiveLinkBackground;
		},
		{
			background: @light_footerActiveLinkBackground;
		},
		@classicTheme: {
			background: @classic_footerActiveLinkBackground;
		},
		@clearBlueTheme: {
			background: @clearBlue_footerActiveLinkBackground;
		}
	);
}

#settings-container #settings-screen .section-container .field > .ui-slider > .ui-widget-header {
	.setThemeStyle(
		{
			background: @dark_borderOuterShadow;
		},
		{
			background: @light_borderOuterShadow;
		},
		@classicTheme: {
			background: @classic_borderOuterShadow;
		},
		@clearBlueTheme: {
			background: @clearBlue_borderOuterShadow;
		}
	);
}
#settings-container #settings-screen .section-container .field > .ui-slider > .ui-slider-handle {
	border-radius: 50%;
}

#settings-container #settings-screen .section-container .field > .ui-slider > .ui-slider-handle.ui-state-active {
	.setThemeStyle(
		{
			background: @dark_borderOuter;
		},
		{
			background: @light_borderOuter;
		},
		@classicTheme: {
			background: @classic_borderOuter;
		},
		@clearBlueTheme: {
			background: @clearBlue_borderOuter;
		}
	);
}

#settings-container #settings-screen .section-container .field > .ui.table {
	margin-top: 1em;
}

#settings-container #settings-screen .section-container p .discord {
	width: 200px;
}


#settings-container #settings-screen .section-container p .discord-link,
#settings-container #settings-screen .section-container p .author-link,
#settings-container #settings-screen .section-container .donation-link {
	user-select: text !important;
	font-weight: bold;
	cursor: text;
}

#settings-container #settings-screen .section-container .donation-link {
	font-size: 1.4em;
	margin: 0.25em 0;

	.setThemeStyle(
		{
			color: @dark_importantText;
		},
		{
			color: @light_importantText;
		},
		@classicTheme: {
			color: @classic_importantText;
		},
		@clearBlueTheme: {
			color: @clearBlue_importantText;
		}
	);
}

#settings-container #settings-screen .section-container .donation-link::selection {
	.setThemeStyle(
		{
			color: @dark_contextMenuItemBackground;
		},
		{
			color: @light_contextMenuItemBackground;
		},
		@classicTheme: {
			color: @classic_contextMenuItemBackground;
		},
		@clearBlueTheme: {
			color: @clearBlue_contextMenuItemBackground;
		}
	);
}

#settings-container #settings-screen .section-container .donation-link::before {
	font-weight: normal;

	.setThemeStyle(
		{
			color: @dark_placeholderInsetBorder;
		},
		{
			color: @light_borderOuterShadow;
		},
		@classicTheme: {
			color: @classic_placeholderInsetBorder;
		},
		@clearBlueTheme: {
			color: @clearBlue_placeholderInsetBorder;
		}
	);
}

#settings-container #settings-screen .section-container .donation-link#donation-cash::before {
	content: "Cash App: ";
}

#settings-container #settings-screen .section-container .donation-link#donation-paypal::before {
	content: "PayPal: ";
}

#settings-container #settings-screen .section-container .donation-link#donation-paypay::before {
	content: "PayPay: ";
}

#settings-container #settings-screen .section-container .donation-link#donation-streamlabs::before {
	content: "Streamlabs: ";
}

#settings-container #settings-screen .section-container .donation-link#donation-kofi::before {
	content: "Ko-fi: ";
}

#settings-container #settings-screen .section-container .donation-link#donation-patreon::before {
	content: "Patreon: ";
}

#settings-container #settings-screen .section-container p .selectable-link {
	user-select: text !important;
	font-weight: bold;
}

#settings-container #settings-screen .section-container h3 {
	margin-bottom: 0px;
	margin-top: 5px;
}

#settings-container #settings-screen .section-container p a,
#settings-container #settings-screen .section-container p a * {
	cursor: pointer;
}

#settings-container #settings-screen .section-container > #credits > ul {
	margin-top: 0;
}

#settings-container #settings-screen .section-container > #credits > ul > li > strong.featured {
	.setThemeStyle(
		{
			color: @dark_importantText;
		},
		{
			color: @light_importantText;
		},
		@classicTheme: {
			color: @classic_importantText;
		},
		@clearBlueTheme: {
			color: @clearBlue_importantText;
		}
	);
}

#settings-container #settings-screen .section-container .changelog-section h4 {
	margin-bottom: 0;
	padding: 0;

	.setThemeStyle(
		{
			color: @dark_borderOuterShadow;
		},
		{
			color: @light_borderOuterShadow;
		},
		@classicTheme: {
			color: @classic_borderOuterShadow;
		},
		@clearBlueTheme: {
			color: @clearBlue_borderOuterShadow;
		}
	);
}

#settings-container #settings-screen .section-container .changelog-section ul {
	margin-top: 0;

	.setThemeStyle(
		{
			color: @dark_headerSubtitleText;
		},
		{
			color: @light_headerSubtitleText;
		},
		@classicTheme: {
			color: @classic_headerSubtitleText;
		},
		@clearBlueTheme: {
			color: @clearBlue_headerSubtitleText;
		}
	);
}

#settings-container #settings-screen .section-container .info-span {
	display: block;
}

#settings-container #settings-screen .section-container #streamers {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	flex: 1;
	margin-top: 4px;
}

#settings-container #settings-screen .section-container #streamers .row {
	display: flex;
	flex-direction: column;
	flex: 1 1 calc(33% - 8px);
	width: calc(33% - 8px) !important;
	max-width: calc(33% - 8px) !important;
	margin: 0 4px 4px 0;
	padding: 4px 8px;
	box-sizing: border-box;

	.setThemeStyle(
		{
			background: @dark_gridItemBackground;
		},
		{
			background: @light_gridItemBackground;
		},
		@classicTheme: {
			background: @classic_gridItemBackground;
		},
		@clearBlueTheme: {
			background: @clearBlue_gridItemBackground;
		}
	);
}

#settings-container #settings-screen .section-container #streamers .row:nth-child(3n),
#settings-container #settings-screen .section-container #streamers .row:last-child {
	margin-right : 0;
}

#settings-container #settings-screen .section-container #streamers .row a {
	display: block;
	width: 100%;
	text-align: center;
	user-select: text !important;

	.setThemeStyle(
		{
			color: @dark_playerDetailSectionTitleText !important;
		},
		{
			color: @light_playerDetailSectionTitleText !important;
		},
		@classicTheme: {
			color: @classic_playerDetailSectionTitleText !important;
		},
		@clearBlueTheme: {
			color: @clearBlue_playerDetailSectionTitleText !important;
		}
	);
}

#settings-container #settings-screen .section-container #streamers .row a .name {
	display: block;
	margin-bottom: 4px;
	cursor: pointer;
	font-size: 14px;
	word-wrap: break-word;
}

#settings-container #settings-screen .section-container #streamers .row a img {
	width: 100%;
	height: auto;
	cursor: pointer;
}

.clear {
	clear: both;
}

#settings-container .ui.inverted.table {
	.setThemeStyle(
		@defaultTheme: {},
		@lightTheme: {
			background-color: @light_toggleBackground;
		},
		@classicTheme: {
			background-color: @classic_toggleBackground;
		},
		@clearBlueTheme: {
			background-color: @clearBlue_toggleBackground;
		}
	);
}
