.setThemeStyle(@defaultTheme: {}, @lightTheme: {}, @minimalTheme: {}, @spellsMode: {}, @classicTheme: {}, @clearBlueTheme: {}) {
	& {
		@defaultTheme();
	}

	body[theme=ffxiv-light] & {
		@lightTheme();
	}

	body[theme=ffxiv-classic] & {
		@classicTheme();
	}

	body[theme=ffxiv-clear-blue] & {
		@clearBlueTheme();
	}

	body.minimal & {
		@minimalTheme();
	}

	body.mode-spells & {
		@spellsMode();
	}
}
