#settings-container {
  height: 100vh;
  margin: 0;
  background: #1e1e1e;
}
body[theme=ffxiv-light] #settings-container {
  background: #f7dbb4;
}
body[theme=ffxiv-classic] #settings-container {
  background: #d9d8d4;
}
body[theme=ffxiv-clear-blue] #settings-container {
  background: #1b294e;
}
body #settings-container,
body #settings-container p,
body #settings-container p * {
  user-select: text;
  cursor: auto;
}
#settings-container .ui.fluid.container {
  width: 100% !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
#settings-container > .row {
  height: 100%;
}
#settings-container #settings-sidebar {
  height: 100%;
  padding: 0;
  margin: 0;
  position: fixed;
  overflow-y: scroll;
}
#settings-container #settings-sidebar #settings-menu {
  margin: 0;
  box-shadow: none;
  border: 0;
  width: 100%;
}
body[theme=ffxiv-clear-blue] #settings-container #settings-sidebar #settings-menu {
  background: #1b294e;
}
#settings-container #settings-sidebar #settings-menu .item {
  border-radius: 0;
  background: #1e1e1e;
  color: rgba(255, 255, 255, 0.6);
}
body[theme=ffxiv-light] #settings-container #settings-sidebar #settings-menu .item {
  background: #f7dbb4;
  color: rgba(0, 0, 0, 0.6);
}
body[theme=ffxiv-classic] #settings-container #settings-sidebar #settings-menu .item {
  background: #d9d8d4;
  color: #100154;
}
body[theme=ffxiv-clear-blue] #settings-container #settings-sidebar #settings-menu .item {
  background: #1b294e;
  color: #a29a87;
}
#settings-container #settings-sidebar #settings-menu .item.active {
  background-color: #554121;
}
body[theme=ffxiv-light] #settings-container #settings-sidebar #settings-menu .item.active {
  background-color: #897252;
}
body[theme=ffxiv-classic] #settings-container #settings-sidebar #settings-menu .item.active {
  background-color: #210096;
  color: #d9d8d4;
}
body[theme=ffxiv-clear-blue] #settings-container #settings-sidebar #settings-menu .item.active {
  background-color: #1e3264;
}
#settings-container #settings-sidebar #settings-menu .item:before {
  background: rgba(255, 255, 255, 0.05);
}
body[theme=ffxiv-light] #settings-container #settings-sidebar #settings-menu .item:before {
  background: rgba(228, 196, 158, 0.8);
}
body[theme=ffxiv-classic] #settings-container #settings-sidebar #settings-menu .item:before {
  background: hsla(60, 100%, 100%, 0.5);
}
body[theme=ffxiv-clear-blue] #settings-container #settings-sidebar #settings-menu .item:before {
  background: rgba(255, 255, 255, 0.05);
}
#settings-container #settings-sidebar #settings-menu .item:not(.active):hover {
  background-color: rgba(0, 0, 0, 0.6);
}
body[theme=ffxiv-light] #settings-container #settings-sidebar #settings-menu .item:not(.active):hover {
  background-color: rgba(91, 77, 62, 0.4);
}
body[theme=ffxiv-classic] #settings-container #settings-sidebar #settings-menu .item:not(.active):hover {
  background-color: rgba(33, 0, 150, 0.5);
  color: #d9d8d4;
}
body[theme=ffxiv-clear-blue] #settings-container #settings-sidebar #settings-menu .item:not(.active):hover {
  background-color: rgba(0, 0, 0, 0.6);
}
#settings-container #settings-screen {
  position: absolute;
  right: 0;
  height: 100%;
  overflow-y: scroll;
  background: #282828;
  box-shadow: -1px 0 0 0 #222;
}
body[theme=ffxiv-light] #settings-container #settings-screen {
  background: #fce8cd;
  box-shadow: -1px 0 0 0 #222;
}
body[theme=ffxiv-classic] #settings-container #settings-screen {
  background: #14044f;
  box-shadow: -1px 0 0 0 #281a69;
}
body[theme=ffxiv-clear-blue] #settings-container #settings-screen {
  background: #254888;
  box-shadow: -1px 0 0 0 #222;
}
#settings-container #settings-screen .ui.form button.save,
#settings-container #settings-screen .ui.form button.export {
  background: #cfc36e;
}
body[theme=ffxiv-light] #settings-container #settings-screen .ui.form button.save,
body[theme=ffxiv-light] #settings-container #settings-screen .ui.form button.export {
  background: #cfc36e;
}
body[theme=ffxiv-classic] #settings-container #settings-screen .ui.form button.save,
body[theme=ffxiv-classic] #settings-container #settings-screen .ui.form button.export {
  background: #f7f8f3;
}
body[theme=ffxiv-clear-blue] #settings-container #settings-screen .ui.form button.save,
body[theme=ffxiv-clear-blue] #settings-container #settings-screen .ui.form button.export {
  background: #3c6ee2;
}
#settings-container #settings-screen .ui.form button.export {
  margin-bottom: 4px;
}
#settings-container #settings-screen .ui.form button.save:active,
#settings-container #settings-screen .ui.form button.export:active {
  background: #bca98a;
}
body[theme=ffxiv-light] #settings-container #settings-screen .ui.form button.save:active,
body[theme=ffxiv-light] #settings-container #settings-screen .ui.form button.export:active {
  background: #763f1c;
}
body[theme=ffxiv-classic] #settings-container #settings-screen .ui.form button.save:active,
body[theme=ffxiv-classic] #settings-container #settings-screen .ui.form button.export:active {
  background: #dcc5ff;
}
body[theme=ffxiv-clear-blue] #settings-container #settings-screen .ui.form button.save:active,
body[theme=ffxiv-clear-blue] #settings-container #settings-screen .ui.form button.export:active {
  background: #eee1c5;
}
#settings-container #settings-screen .section-container {
  margin-bottom: 1.5rem;
}
#settings-container #settings-screen .section-container:nth-child(3) {
  margin-top: -30px;
}
#settings-container #settings-screen .section-container > h2 {
  margin-bottom: 0;
  color: #bca98a;
}
body[theme=ffxiv-light] #settings-container #settings-screen .section-container > h2 {
  color: #763f1c;
}
body[theme=ffxiv-classic] #settings-container #settings-screen .section-container > h2 {
  color: #dcc5ff;
}
body[theme=ffxiv-clear-blue] #settings-container #settings-screen .section-container > h2 {
  color: #eee1c5;
}
#settings-container #settings-screen .section-container p,
#settings-container #settings-screen .section-container .field label,
#settings-container #settings-screen .section-container .field .ui.toggle.checkbox input label {
  font-style: normal;
  font-weight: normal;
  font-size: 1em;
}
#settings-container #settings-screen .section-container .field label,
#settings-container #settings-screen .section-container p,
#settings-container #settings-screen .section-container h3,
#settings-container #settings-screen .section-container .field .ui.toggle.checkbox input:checked ~ label {
  color: rgba(255, 255, 255, 0.7) !important;
}
body[theme=ffxiv-light] #settings-container #settings-screen .section-container .field label,
body[theme=ffxiv-light] #settings-container #settings-screen .section-container p,
body[theme=ffxiv-light] #settings-container #settings-screen .section-container h3,
body[theme=ffxiv-light] #settings-container #settings-screen .section-container .field .ui.toggle.checkbox input:checked ~ label {
  color: rgba(0, 0, 0, 0.7) !important;
}
body[theme=ffxiv-classic] #settings-container #settings-screen .section-container .field label,
body[theme=ffxiv-classic] #settings-container #settings-screen .section-container p,
body[theme=ffxiv-classic] #settings-container #settings-screen .section-container h3,
body[theme=ffxiv-classic] #settings-container #settings-screen .section-container .field .ui.toggle.checkbox input:checked ~ label {
  color: rgba(255, 255, 255, 0.7) !important;
}
body[theme=ffxiv-clear-blue] #settings-container #settings-screen .section-container .field label,
body[theme=ffxiv-clear-blue] #settings-container #settings-screen .section-container p,
body[theme=ffxiv-clear-blue] #settings-container #settings-screen .section-container h3,
body[theme=ffxiv-clear-blue] #settings-container #settings-screen .section-container .field .ui.toggle.checkbox input:checked ~ label {
  color: #eee1c5 !important;
}
#settings-container #settings-screen .section-container .field .ui.toggle.checkbox input ~ label:before {
  background: rgba(255, 255, 255, 0.05) !important;
}
body[theme=ffxiv-light] #settings-container #settings-screen .section-container .field .ui.toggle.checkbox input ~ label:before {
  background: rgba(0, 0, 0, 0.02) !important;
}
body[theme=ffxiv-classic] #settings-container #settings-screen .section-container .field .ui.toggle.checkbox input ~ label:before {
  background: rgba(255, 255, 255, 0.12) !important;
}
body[theme=ffxiv-clear-blue] #settings-container #settings-screen .section-container .field .ui.toggle.checkbox input ~ label:before {
  background: rgba(255, 255, 255, 0.05) !important;
}
#settings-container #settings-screen .section-container .field .ui.toggle.checkbox input ~ label:hover:before {
  background: rgba(255, 255, 255, 0.07) !important;
}
body[theme=ffxiv-light] #settings-container #settings-screen .section-container .field .ui.toggle.checkbox input ~ label:hover:before {
  background: rgba(91, 68, 49, 0.09) !important;
}
body[theme=ffxiv-classic] #settings-container #settings-screen .section-container .field .ui.toggle.checkbox input ~ label:hover:before {
  background: rgba(255, 255, 255, 0.07) !important;
}
body[theme=ffxiv-clear-blue] #settings-container #settings-screen .section-container .field .ui.toggle.checkbox input ~ label:hover:before {
  background: rgba(255, 255, 255, 0.07) !important;
}
#settings-container #settings-screen .section-container .field .ui.toggle.checkbox input:checked ~ label:before {
  background: rgba(188, 169, 138, 0.65) !important;
}
body[theme=ffxiv-light] #settings-container #settings-screen .section-container .field .ui.toggle.checkbox input:checked ~ label:before {
  background: rgba(188, 169, 138, 0.65) !important;
}
body[theme=ffxiv-classic] #settings-container #settings-screen .section-container .field .ui.toggle.checkbox input:checked ~ label:before {
  background: rgba(188, 169, 138, 0.65) !important;
}
body[theme=ffxiv-clear-blue] #settings-container #settings-screen .section-container .field .ui.toggle.checkbox input:checked ~ label:before {
  background: #eee1c5 !important;
}
#settings-container #settings-screen .section-container .field .ui.dropdown,
#settings-container #settings-screen .section-container .field textarea,
#settings-container #settings-screen .section-container .field > div.ui.input > input[type=text] {
  background: rgba(255, 255, 255, 0.2) !important;
}
body[theme=ffxiv-light] #settings-container #settings-screen .section-container .field .ui.dropdown,
body[theme=ffxiv-light] #settings-container #settings-screen .section-container .field textarea,
body[theme=ffxiv-light] #settings-container #settings-screen .section-container .field > div.ui.input > input[type=text] {
  background: rgba(91, 68, 49, 0.2) !important;
}
body[theme=ffxiv-classic] #settings-container #settings-screen .section-container .field .ui.dropdown,
body[theme=ffxiv-classic] #settings-container #settings-screen .section-container .field textarea,
body[theme=ffxiv-classic] #settings-container #settings-screen .section-container .field > div.ui.input > input[type=text] {
  background: rgba(255, 255, 255, 0.2) !important;
}
body[theme=ffxiv-clear-blue] #settings-container #settings-screen .section-container .field .ui.dropdown,
body[theme=ffxiv-clear-blue] #settings-container #settings-screen .section-container .field textarea,
body[theme=ffxiv-clear-blue] #settings-container #settings-screen .section-container .field > div.ui.input > input[type=text] {
  background: rgba(255, 255, 255, 0.2) !important;
}
#settings-container #settings-screen .section-container .field textarea,
#settings-container #settings-screen .section-container .field .code pre,
#settings-container #settings-screen .section-container .field > div.ui.input > input[type=text] {
  cursor: text;
  color: rgba(255, 255, 255, 0.7) !important;
}
body[theme=ffxiv-light] #settings-container #settings-screen .section-container .field textarea,
body[theme=ffxiv-light] #settings-container #settings-screen .section-container .field .code pre,
body[theme=ffxiv-light] #settings-container #settings-screen .section-container .field > div.ui.input > input[type=text] {
  color: rgba(0, 0, 0, 0.7) !important;
}
body[theme=ffxiv-classic] #settings-container #settings-screen .section-container .field textarea,
body[theme=ffxiv-classic] #settings-container #settings-screen .section-container .field .code pre,
body[theme=ffxiv-classic] #settings-container #settings-screen .section-container .field > div.ui.input > input[type=text] {
  color: rgba(255, 255, 255, 0.7) !important;
}
body[theme=ffxiv-clear-blue] #settings-container #settings-screen .section-container .field textarea,
body[theme=ffxiv-clear-blue] #settings-container #settings-screen .section-container .field .code pre,
body[theme=ffxiv-clear-blue] #settings-container #settings-screen .section-container .field > div.ui.input > input[type=text] {
  color: #eee1c5 !important;
}
#settings-container #settings-screen .section-container .field .code {
  min-height: 300px;
}
#settings-container #settings-screen .section-container .field textarea,
#settings-container #settings-screen .section-container .field .code textarea,
#settings-container #settings-screen .section-container .field .code pre {
  padding: 4px !important;
}
#settings-container #settings-screen .section-container .field .code textarea {
  height: auto !important;
  min-height: 100% !important;
}
#settings-container #settings-screen .section-container .field .ui.dropdown a.ui.label {
  background: hsla(53, 50%, 62%, 0.5);
}
body[theme=ffxiv-light] #settings-container #settings-screen .section-container .field .ui.dropdown a.ui.label {
  background: hsla(26, 31%, 26%, 0.5);
}
body[theme=ffxiv-classic] #settings-container #settings-screen .section-container .field .ui.dropdown a.ui.label {
  background: hsl(72, 2%, 97%);
}
body[theme=ffxiv-clear-blue] #settings-container #settings-screen .section-container .field .ui.dropdown a.ui.label {
  background: #a29a87;
}
#settings-container #settings-screen .section-container .field .ui.dropdown input.search,
#settings-container #settings-screen .section-container .field .ui.dropdown div.text {
  color: rgba(255, 255, 255, 0.7);
}
body[theme=ffxiv-light] #settings-container #settings-screen .section-container .field .ui.dropdown input.search,
body[theme=ffxiv-light] #settings-container #settings-screen .section-container .field .ui.dropdown div.text {
  color: rgba(0, 0, 0, 0.7);
}
body[theme=ffxiv-classic] #settings-container #settings-screen .section-container .field .ui.dropdown input.search,
body[theme=ffxiv-classic] #settings-container #settings-screen .section-container .field .ui.dropdown div.text {
  color: rgba(255, 255, 255, 0.7);
}
body[theme=ffxiv-clear-blue] #settings-container #settings-screen .section-container .field .ui.dropdown input.search,
body[theme=ffxiv-clear-blue] #settings-container #settings-screen .section-container .field .ui.dropdown div.text {
  color: #eee1c5;
}
#settings-container #settings-screen .section-container .field > .ui-slider {
  border: 0;
  background: rgba(255, 255, 255, 0.2);
}
body[theme=ffxiv-light] #settings-container #settings-screen .section-container .field > .ui-slider {
  background: rgba(91, 68, 49, 0.2);
}
body[theme=ffxiv-classic] #settings-container #settings-screen .section-container .field > .ui-slider {
  background: rgba(255, 255, 255, 0.2);
}
body[theme=ffxiv-clear-blue] #settings-container #settings-screen .section-container .field > .ui-slider {
  background: rgba(255, 255, 255, 0.2);
}
#settings-container #settings-screen .section-container .field > .ui-slider > .ui-widget-header {
  background: hsla(53, 50%, 62%, 0.5);
}
body[theme=ffxiv-light] #settings-container #settings-screen .section-container .field > .ui-slider > .ui-widget-header {
  background: hsla(26, 31%, 26%, 0.5);
}
body[theme=ffxiv-classic] #settings-container #settings-screen .section-container .field > .ui-slider > .ui-widget-header {
  background: hsl(72, 2%, 97%);
}
body[theme=ffxiv-clear-blue] #settings-container #settings-screen .section-container .field > .ui-slider > .ui-widget-header {
  background: #a29a87;
}
#settings-container #settings-screen .section-container .field > .ui-slider > .ui-slider-handle {
  border-radius: 50%;
}
#settings-container #settings-screen .section-container .field > .ui-slider > .ui-slider-handle.ui-state-active {
  background: #cfc36e;
}
body[theme=ffxiv-light] #settings-container #settings-screen .section-container .field > .ui-slider > .ui-slider-handle.ui-state-active {
  background: #cfc36e;
}
body[theme=ffxiv-classic] #settings-container #settings-screen .section-container .field > .ui-slider > .ui-slider-handle.ui-state-active {
  background: #f7f8f3;
}
body[theme=ffxiv-clear-blue] #settings-container #settings-screen .section-container .field > .ui-slider > .ui-slider-handle.ui-state-active {
  background: #3c6ee2;
}
#settings-container #settings-screen .section-container .field > .ui.table {
  margin-top: 1em;
}
#settings-container #settings-screen .section-container p .discord {
  width: 200px;
}
#settings-container #settings-screen .section-container p .discord-link,
#settings-container #settings-screen .section-container p .author-link,
#settings-container #settings-screen .section-container .donation-link {
  user-select: text !important;
  font-weight: bold;
  cursor: text;
}
#settings-container #settings-screen .section-container .donation-link {
  font-size: 1.4em;
  margin: 0.25em 0;
  color: #bca98a;
}
body[theme=ffxiv-light] #settings-container #settings-screen .section-container .donation-link {
  color: #763f1c;
}
body[theme=ffxiv-classic] #settings-container #settings-screen .section-container .donation-link {
  color: #dcc5ff;
}
body[theme=ffxiv-clear-blue] #settings-container #settings-screen .section-container .donation-link {
  color: #eee1c5;
}
#settings-container #settings-screen .section-container .donation-link::selection {
  color: #eee1c5;
}
body[theme=ffxiv-light] #settings-container #settings-screen .section-container .donation-link::selection {
  color: #7f4422;
}
body[theme=ffxiv-classic] #settings-container #settings-screen .section-container .donation-link::selection {
  color: #d9d6dd;
}
body[theme=ffxiv-clear-blue] #settings-container #settings-screen .section-container .donation-link::selection {
  color: #eee1c5;
}
#settings-container #settings-screen .section-container .donation-link::before {
  font-weight: normal;
  color: hsla(53, 50%, 62%, 0.5);
}
body[theme=ffxiv-light] #settings-container #settings-screen .section-container .donation-link::before {
  color: hsla(26, 31%, 26%, 0.5);
}
body[theme=ffxiv-classic] #settings-container #settings-screen .section-container .donation-link::before {
  color: hsl(239, 59%, 77%);
}
body[theme=ffxiv-clear-blue] #settings-container #settings-screen .section-container .donation-link::before {
  color: #3c6ee2;
}
#settings-container #settings-screen .section-container .donation-link#donation-cash::before {
  content: "Cash App: ";
}
#settings-container #settings-screen .section-container .donation-link#donation-paypal::before {
  content: "PayPal: ";
}
#settings-container #settings-screen .section-container .donation-link#donation-paypay::before {
  content: "PayPay: ";
}
#settings-container #settings-screen .section-container .donation-link#donation-streamlabs::before {
  content: "Streamlabs: ";
}
#settings-container #settings-screen .section-container .donation-link#donation-kofi::before {
  content: "Ko-fi: ";
}
#settings-container #settings-screen .section-container .donation-link#donation-patreon::before {
  content: "Patreon: ";
}
#settings-container #settings-screen .section-container p .selectable-link {
  user-select: text !important;
  font-weight: bold;
}
#settings-container #settings-screen .section-container h3 {
  margin-bottom: 0px;
  margin-top: 5px;
}
#settings-container #settings-screen .section-container p a,
#settings-container #settings-screen .section-container p a * {
  cursor: pointer;
}
#settings-container #settings-screen .section-container > #credits > ul {
  margin-top: 0;
}
#settings-container #settings-screen .section-container > #credits > ul > li > strong.featured {
  color: #bca98a;
}
body[theme=ffxiv-light] #settings-container #settings-screen .section-container > #credits > ul > li > strong.featured {
  color: #763f1c;
}
body[theme=ffxiv-classic] #settings-container #settings-screen .section-container > #credits > ul > li > strong.featured {
  color: #dcc5ff;
}
body[theme=ffxiv-clear-blue] #settings-container #settings-screen .section-container > #credits > ul > li > strong.featured {
  color: #eee1c5;
}
#settings-container #settings-screen .section-container .changelog-section h4 {
  margin-bottom: 0;
  padding: 0;
  color: hsla(53, 50%, 62%, 0.5);
}
body[theme=ffxiv-light] #settings-container #settings-screen .section-container .changelog-section h4 {
  color: hsla(26, 31%, 26%, 0.5);
}
body[theme=ffxiv-classic] #settings-container #settings-screen .section-container .changelog-section h4 {
  color: hsl(72, 2%, 97%);
}
body[theme=ffxiv-clear-blue] #settings-container #settings-screen .section-container .changelog-section h4 {
  color: #a29a87;
}
#settings-container #settings-screen .section-container .changelog-section ul {
  margin-top: 0;
  color: hsla(0, 0%, 100%, 0.5);
}
body[theme=ffxiv-light] #settings-container #settings-screen .section-container .changelog-section ul {
  color: hsla(0, 0%, 0%, 0.5);
}
body[theme=ffxiv-classic] #settings-container #settings-screen .section-container .changelog-section ul {
  color: #e9d6ff;
}
body[theme=ffxiv-clear-blue] #settings-container #settings-screen .section-container .changelog-section ul {
  color: hsla(0, 0%, 100%, 0.5);
}
#settings-container #settings-screen .section-container .info-span {
  display: block;
}
#settings-container #settings-screen .section-container #streamers {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  flex: 1;
  margin-top: 4px;
}
#settings-container #settings-screen .section-container #streamers .row {
  display: flex;
  flex-direction: column;
  flex: 1 1 calc(33% - 8px);
  width: calc(33% - 8px) !important;
  max-width: calc(33% - 8px) !important;
  margin: 0 4px 4px 0;
  padding: 4px 8px;
  box-sizing: border-box;
  background: rgba(0, 0, 0, 0.2);
}
body[theme=ffxiv-light] #settings-container #settings-screen .section-container #streamers .row {
  background: rgba(0, 0, 0, 0.1);
}
body[theme=ffxiv-classic] #settings-container #settings-screen .section-container #streamers .row {
  background: #5150c8;
}
body[theme=ffxiv-clear-blue] #settings-container #settings-screen .section-container #streamers .row {
  background: rgba(0, 0, 0, 0.2);
}
#settings-container #settings-screen .section-container #streamers .row:nth-child(3n),
#settings-container #settings-screen .section-container #streamers .row:last-child {
  margin-right: 0;
}
#settings-container #settings-screen .section-container #streamers .row a {
  display: block;
  width: 100%;
  text-align: center;
  user-select: text !important;
  color: rgba(255, 255, 255, 0.7) !important;
}
body[theme=ffxiv-light] #settings-container #settings-screen .section-container #streamers .row a {
  color: rgba(0, 0, 0, 0.7) !important;
}
body[theme=ffxiv-classic] #settings-container #settings-screen .section-container #streamers .row a {
  color: rgba(255, 255, 255, 0.7) !important;
}
body[theme=ffxiv-clear-blue] #settings-container #settings-screen .section-container #streamers .row a {
  color: #eee1c5 !important;
}
#settings-container #settings-screen .section-container #streamers .row a .name {
  display: block;
  margin-bottom: 4px;
  cursor: pointer;
  font-size: 14px;
  word-wrap: break-word;
}
#settings-container #settings-screen .section-container #streamers .row a img {
  width: 100%;
  height: auto;
  cursor: pointer;
}
.clear {
  clear: both;
}
body[theme=ffxiv-light] #settings-container .ui.inverted.table {
  background-color: #897252;
}
body[theme=ffxiv-classic] #settings-container .ui.inverted.table {
  background-color: #210096;
}
body[theme=ffxiv-clear-blue] #settings-container .ui.inverted.table {
  background-color: #1e3264;
}
